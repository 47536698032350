import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/maersk-pbm/src/components/Layout/Layout.js";
import { graphql, navigate, Link } from "gatsby";
import Container from "../../components/Layout/Container";
import Hero from "../../components/Layout/Hero";
import Button from "../../components/Elements/Button";
import Media from "../../components/Elements/Media";
import Img from "gatsby-image";
import medicines from "../../../static/images/coverage_medicines.png";
import otc from "../../../static/images/coverage_otc.png";
import requiredReceipt from "../../../static/images/required_receipt--teal.png";
import requiredRx from "../../../static/images/required_rx--teal.png";
import requiredDocuments from "../../../static/images/required_documents--teal.png";
import requiredOnline from "../../../static/images/required_online--teal.png";
import processTriangleGrey from "../../../static/images/process_triangle--grey.png";
import epharmacyOrderClickTeal from "../../../static/images/epharmacy/epharmacy_order-click--teal.png";
import epharmacyOrderUploadTeal from "../../../static/images/epharmacy/epharmacy_order-upload--teal.png";
import epharmacyOrderDetailsTeal from "../../../static/images/epharmacy/epharmacy_order-details--teal.png";
import epharmacyConfirmSMSTeal from "../../../static/images/epharmacy/epharmacy_confirm-SMS--teal.png";
import epharmacyConfirmDeliveryTeal from "../../../static/images/epharmacy/epharmacy_confirm-delivery--teal.png";
import epharmacyReceiveDeliveredTeal from "../../../static/images/epharmacy/epharmacy_receive-delivered--teal.png";
export const query = graphql`
  query {
    epharmacy: file(relativePath: { eq: "hero_epharmacy_long.png" }) 
    {
      childImageSharp {
        fluid(
          maxWidth: 2000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <Hero color="primary" size="medium" backgroundImage={props.data.epharmacy.childImageSharp.fluid.src} mdxType="Hero">
      <Container isCentered mdxType="Container">
        <Container desktop={5} mdxType="Container">
          <div className="m-1 pt-2 pb-2">
            <h1>{`ePharmacy`}</h1>
            <p>{`Order your meds online and have them delivered to your home or office`}</p>
            <Button color="primary" size="medium" className="mt-2" onClick={() => navigate("/epharmacy/mechanics")} mdxType="Button">Order Cashless</Button>
          </div>
        </Container>
      </Container>
    </Hero>
    <Hero mdxType="Hero">
      <Container isCentered mdxType="Container">
        <h3 className="has-text-centered">How ePharmacy works</h3>
        <div className="columns has-text-centered">
          <div className="column">
            <h4>{`Order`}</h4>
            <div className="has-text-left">
              <Media image={epharmacyOrderClickTeal} mdxType="Media"> Click <strong>Order Cashless</strong> at <Link to="/" className="has-text-weight-bold" mdxType="Link">maersk.medgrocer.com</Link></Media>
              <Media image={epharmacyOrderUploadTeal} mdxType="Media">Upload required documents</Media>
              <Media image={epharmacyOrderDetailsTeal} mdxType="Media">Input personal details</Media>
            </div>
          </div>
          <div className="column is-1 mt-2 is-hidden-mobile"> 
            <img src={processTriangleGrey} alt="next" width="40%" />
          </div>
          <div className="column">
            <h4>{`Confirm`}</h4>
            <div className="has-text-left">
              <Media image={epharmacyConfirmSMSTeal} mdxType="Media">Receive SMS confirming order</Media>
              <Media image={epharmacyConfirmDeliveryTeal} mdxType="Media">Receive SMS when meds are ready for delivery</Media>
            </div>
          </div>
          <div className="column is-1 mt-2 is-hidden-mobile"> 
            <img src={processTriangleGrey} alt="next" width="40%" />
          </div>
          <div className="column">
            <h4>{`Receive`}</h4>
            <div className="has-text-left">
              <Media image={epharmacyReceiveDeliveredTeal} mdxType="Media">Receive meds at your convenience</Media>
            </div>
          </div>
        </div>
      </Container>
    </Hero>
    <Hero isCentered color="light" mdxType="Hero">
      <p>{`Want to maximize your Medical Allowance?`}</p>
      <h3 className="m-1">Check before you request</h3>
    </Hero>
    <Hero mdxType="Hero">
      <Container isCentered mdxType="Container">
        <h3 className="has-text-left">What's covered</h3>
        <div className="columns is-centered is-vcentered">
          <div className="column is-2">
            <Container desktop={12} mobile={10} isCentered mdxType="Container">
              <img src={medicines} alt="medicines" className="image is-3by1" />
            </Container>
          </div>
          <div className="column mr-3"> 
            <h4 className="has-text-primary"> Ethical drugs </h4>
            <p>{`Require a prescription, used to treat serious conditions`}</p>
          </div>
          <div className="column is-2">
            <Container desktop={12} mobile={10} isCentered mdxType="Container"> 
              <img src={otc} alt="over-the-counter medicines" className="image is-3by1" />
            </Container>
          </div>
          <div className="column">
            <h4 className="has-text-primary"> Over-the-counter drugs </h4>
            <p>{`May be prescribed but can be bought without a prescription, including vitamins and supplements`}</p>
          </div>
        </div>
        <h3 className="has-text-left">What's required</h3>
        <div className="columns is-vcentered">
          <div className="column is-2">
            <Container desktop={12} mobile={10} isCentered mdxType="Container">
              <img src={requiredRx} alt="prescription requirements" width="80%" className="image is-3by1" />
            </Container>
          </div>
          <div className="column content mr-3">
            <h4 className="has-text-primary"> Prescription for Purchases of </h4>
- Ethical drugs (e.g. prescription drugs, vaccines)
- Medicine orders Php1,000 or more
- Antipsychotic/ antidepressant drugs
          </div>
          <div className="column is-2">
            <Container desktop={12} mobile={10} isCentered mdxType="Container">
              <img src={requiredDocuments} alt="prescription" width="80%" className="image is-3by1" />
            </Container>
          </div> 
          <div className="column content">
            <h4 className="has-text-primary"> Domestic/Common Law Spouse</h4>
            <ul>
              <li parentName="ul">{`NSO birth certificate`}</li>
              <li parentName="ul">{`NSO marriage contract (if applicable)`}</li>
              <li parentName="ul">{`Barangay certification of co-habitation for more than 12 months`}</li>
              <li parentName="ul">{`NSO Certification of No Marriage (CENOMAR)`}</li>
            </ul>
          </div>
        </div>
      </Container>
    </Hero>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      